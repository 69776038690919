const imageMixin = {
  methods: {
    imgUrl(imgObject) {
      if (imgObject) {
        return imgObject.image_url;
      }
      return "";
    }
  }
};

export default imageMixin;
