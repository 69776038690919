<template>
  <div>
    <div id="header-common">
      <div class="header" :class="{ default: defaultStyle }">
        <slot name="headerButton"></slot>
        <h1 v-if="useTitle" class="f-w6">{{ title }}</h1>
        <img
          v-if="useLogo"
          class="header__logo"
          :src="require('@/assets/image/logo-prego.svg')"
          alt="Prego"
        />
      </div>
    </div>
    <NotifyHeader />
  </div>
</template>

<script>
import NotifyHeader from "@/components/NotifyHeader.vue";

export default {
  name: "HeaderCommon",
  components: { NotifyHeader },
  data() {
    return {
      displaySearchButton: true
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    showSearch: {
      type: Boolean
    },
    useLogo: {
      type: Boolean,
      default: false
    },
    defaultStyle: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    useTitle() {
      return this.title.length > 0;
    }
  },
  mounted() {
    if (typeof this.showSearch !== undefined) {
      this.displaySearchButton = true;
    } else {
      this.displaySearchButton = false;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixin";
@import "../../assets/scss/_fontFamily.scss";
#header-common {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  max-width: 1080px;
  margin: auto;
  z-index: 10;
}
.header {
  height: 42px;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  @include flexCenter();

  &.default {
    // top: 0;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 50;
    position: relative;
    // position: fixed;
    background: #ffffff;
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    color: #000000;
    font-family: "Hiragino Kaku Gothic Pro W3"; // change font w3
    font-weight: bold;
  }
  &__logo {
    display: block;
    width: 79px;
    height: 20px;
  }
}

.text-noti {
  //font-size: 16px;
  //color: #ffffff;
  //background: #4cb3be;
  //height: 32px;
  //padding-top: 4px;
  //font-weight: bold;
}

@media screen and (min-width: 1200px) {
  .header {
    height: 61px;
    width: 1080px;
    &__logo {
      width: 135px;
      height: 34px;
    }

    h1 {
      font-size: 24px;
    }
  }
}
</style>
